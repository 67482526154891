import TopItem from "./TopItem";
import FDBar from "./FDBar";
import FDMap from "./FDMap";
import FDRecipeTable from "./FDRecipeTable";
import FDHeat from "./FDHeat";
import FDPie from "./FDPie";
import FDLine from "./FDLine";
import FDInfo from "./FDInfo";
import FDFlow from "./FDFlow";
import Grid from "@mui/material/Grid";
import {all_states} from "../../../store/location_constants";
import React, {useMemo} from "react";
import FDTopBar from "./FDTopBar";
import FDPreviewAlert from "./FDPreviewAlert";
import FDMonetaryAndNextSteps from "./FDMonetaryAndNextSteps";
import { useSelector } from "react-redux";
import FDAlertFeed from "./FDAlertFeed";
import FDSelectGroupV2 from "./FDSelectGroupV2";
import FDSearchV2 from "./FDSearchV2";
import FDRadioGroupV2 from "./FDRadioGroupV2";
import { calculation } from "./DashboardUtils";

const component_map = {
  top: TopItem,
  bar: FDBar,
  select: FDSelectGroupV2,
  radio: FDRadioGroupV2,
  toggle: FDRadioGroupV2,
  map: FDMap,
  table: FDRecipeTable,
  heat: FDHeat,
  pie: FDPie,
  column: FDBar,
  line: FDLine,
  search: FDSearchV2,
  circularProgress: FDPie,
  waterfall: FDBar,
  infobox: FDInfo,
  order_flow: FDFlow
};

const getGridItemSize = (component_type, size) => {
  if (component_type === "top" || component_type === "select" || component_type === "search") {
    return { lg: size, md: size, sm: 6, xs: 12 }
  } else if (component_type === "radio") {
    // how to get no size settings here so
    return { lg: 'auto', md: 'auto', sm: 6, xs: 12 }
  }
  return { lg: size, md: size, sm: size, xs: 12 };
}


export const DashboardView = ({ state, layout, cancelAction }) => {
  const rows = useSelector(state => state.dashboard.rows);
  const hideAlerts = useMemo(() => {
    if (state.preview) {
      // hides FDAlertFeed for detail table in preview
      if (Object.values(layout.components).some((element) => element.type === "table" && element.hasDetailColumns)) {
        return true;
      }
    }
    return !!state?.thisDevApp?.tags?.includes("view_config__hide_alerts");

  }, [layout, state]);

  const getGrid = (item, index, level) => {
    //console.log("element: ", item)
    if (item.layout) {
      return (
        <Grid item key={level + "-" + index} {...(item.size ? { lg : item.size, xs: 12 }: {}) }>
          <Grid container spacing={2}>
            {item.layout.map((underlying, underlying_index) => {
              return getGrid(underlying, underlying_index, level + 1);
            })}
          </Grid>
        </Grid>
      );
    } else {
      // console.log("item", item)
      let component_type = layout["components"][item.id].type;

      if (!component_type) {
        component_type = layout["components"][item.id].series[0].type;
      }
      //console.log(component_type)
      let MyComponent = component_map[component_type];
      let params = JSON.parse(JSON.stringify(layout["components"][item.id]));
      // new for displayConfig
      params.objectName = item.id;

      if (component_type === "top" && params.figure) {
        let table = rows[params.sourceTable].data;
        let yIndex = table[0].indexOf(params.figure.field);

        let result = undefined;
        if (params.figure.formatting) {
          params.formatting = params.figure.formatting;
        }

        if (params.figure.operation) {
          result = calculation(
            {
              operation: params.figure.operation,
              scalar: params.figure.scalar ? params.figure.scalar : undefined,
              field: yIndex,
              xColumnIndex: 0,
              category: "__ALL",
            },
            params.sourceTable,
            rows
          );
        } else {
          result = params.figure.value;
        }
        params.figureData = result;

        if(params.figure.type && params.figure.type === "compare") {
          // deals with comparisons
          let compareData = {};
          compareData.subtitle = params.figure.config.subtitle;
          compareData.isPositiveGood = params.figure.config.isPositiveGood;

          let compare_result = calculation(
            {
              "operation": params.figure.operation,
              "scalar": params.figure.scalar ? params.figure.scalar : undefined,
              "field": table[0].indexOf(params.figure.config.field),
              "xColumnIndex": 0,
              "category": "__ALL"
            }, params.sourceTable, rows);

          if(params.figure.config.changeType === "geometric") {
            compareData.type = "geometric";
            compareData.figure = (result/compare_result) * 100 - 100 ;
          } else {
            compareData.type = "arithmetic";
            compareData.figure = result - compare_result;
          }

          params.compareData = compareData;
        }
        if (hideAlerts) {
          params.applyBackground = true;
        }
      }

      if (component_type === "select" || component_type === "radio" || component_type === "toggle") {
        //console.log(rows[params.sourceTable]);
        if(layout["components"][item.id].additionalFilterTables != undefined) {
          params.additionalTables = layout["components"][item.id].additionalFilterTables
        } else {
          params.additionalTables = undefined;
        }

        if(state.flagFilterComponentName != undefined && state.flagFilterComponentName != "" && state.flagFilterInput != undefined && state.flagFilterInput != "") {
          if(layout["components"][item.id].name == state.flagFilterComponentName) {
            params.flagFilterComponentName = state.flagFilterComponentName;
            params.flagFilterInput = state.flagFilterInput;
          }
        }
        if (component_type === "toggle") {
          params.toggle = () => {}/*toggle*/;

        } else if (params.date_config) {
          const fieldIndex = rows[params.sourceTable].data[0].indexOf(
            params.field
          );
          params.items = [
            ...new Set(
              rows[params.sourceTable].data.slice(1).map((item) => {
                let date = new Date(item[fieldIndex]);
                if (params.date_config.type === "year") {
                  return date.getFullYear();
                } else if (params.date_config.type === "month") {
                  return date.getMonth();
                }
              })
            ),
          ].sort((a, b) => a - b);
          params.filter = () => {}/*filter*/;

        } else if (!params.items) {
          const fieldIndex = rows[params.sourceTable].data[0].indexOf(
            params.field
          );
          let set = [
            ...new Set(
              rows[params.sourceTable].data
                .slice(1)
                .map((item) => item[fieldIndex])
            ),
          ];
          // console.log("getGrid - filter component:", item.id);
          // console.log("Set", set);
          if(!set.some(isNaN)) {
            params.items = set.sort((a, b) => a - b);
          } else {
            params.items = set.sort();
          }

          params.filter = () => {}/*filter*/;
        }

        if (params.descend) {
          params.items = params.items.reverse();
        }
      }

      if (component_type === "search") {
        if(layout["components"][item.id].additionalFilterTables != undefined) {
          params.additionalTables = layout["components"][item.id].additionalFilterTables
        } else {
          params.additionalTables = undefined;
        }

        if(state.flagFilterComponentName != undefined && state.flagFilterComponentName != "" && state.flagFilterInput != undefined && state.flagFilterInput != "") {

          if(layout["components"][item.id].name === state.flagFilterComponentName) {
            params.flagFilterComponentName = state.flagFilterComponentName;
            params.flagFilterInput = state.flagFilterInput;
          }
        }
        params.filter = () => {}/*filter*/;
      }

      if (component_type === "bar" || component_type === "column" || component_type === "waterfall" || component_type === "line") {
        for (var i = 0; i < params.series.length; i++) {
          let new_data = [];

          let table = rows[params.sourceTable].data;
          let tableNoHeader = table.slice(1);
          let dataColumnX = "";
          let dataColumnY = params.series[i].dataColumnY;
          let xIndex = -1;
          let yIndex = table[0].indexOf(dataColumnY);

          // Waterfall-specific configuraiton
          if (component_type === "waterfall") {
            params.type = "waterfall";

            if (!params.categoryColumnLabels) {
              params.categoryColumnLabels = [];
            }

            for(var j = 0; j < params.series[i].data.length; j++) {
              params.categoryColumnLabels.push(params.series[i].data[j].name);

              if(!params.series[i].data[j].isSum && !params.series[i].data[j].isIntermediateSum) {

                const scalar = !params.series[i].data[j].downStep ? 1 : -1;
                let calculationParams = {};
                let useCategory = "__ALL";

                if (["sumif", "countif", "meanif", "sum", "count"].includes(params.series[i].data[j].operation)) {
                  let fieldIndex = table[0].indexOf(params.series[i].data[j].field);
                  let ifIndex = table[0].indexOf(params.series[i].data[j].conditionFieldName);

                  Object.assign(calculationParams, {
                    operation: params.series[i].data[j].operation,
                    field: fieldIndex,
                    if_field: ifIndex,
                    if_value: params.series[i].data[j].negativeConditional ? 0 : 1,
                    category: useCategory,
                    scalar:
                      params.formatting && params.formatting.scalar
                        ? params.formatting.scalar
                        : undefined,
                  });
                  //console.log(calculationParams);
                  params.series[i].data[j].y = scalar * calculation(calculationParams, params.sourceTable, rows);
                }
              }
            }
            continue
          }


          // Category charts
          if (params.categoryColumnName) {
            if (!params.xAxis) {
              params.xAxis = {};
            }
            // Manually specified columns for categories
            let columnCategories = false;
            if (typeof params.categoryColumnName === typeof []) {
              dataColumnX = "";
              xIndex = 0;
              params.xAxis["categories"] = params.categoryColumnName;
              columnCategories = true;
            }
            // Categories are values in a column
            else {
              dataColumnX = params.categoryColumnName;
              xIndex = table[0].indexOf(dataColumnX);
              if(params.categoryOrdered) {
                params.xAxis["categories"] = params.categoryOrdered;
              } else {
                params.xAxis["categories"] = tableNoHeader.map((row) => row[xIndex]);
              }
              //console.log(params.xAxis);
            }

            // Dynamic Calculation
            if (params.series[i].aggregation) {
              let calculationParams = {
                operation: params.series[i].aggregation,
                scalar:
                  params.formatting && params.formatting.scalar
                    ? params.formatting.scalar
                    : undefined,
                field: yIndex,
                xColumnIndex: xIndex,
              };
              //console.log(calculationParams);
              //console.log(params.series[i].aggregation);

              // Get unique categories
              let categoriesUnique = params.categoryOrdered ? params.categoryOrdered : [...new Set(params.xAxis.categories)];

              if (["addcols"].includes(params.series[i].aggregation)) {
                params.xAxis.categories = categoriesUnique;

                params.series[i].data = categoriesUnique.map((category) => {

                  let ifIndex = undefined;
                  let useCategory = category;
                  if (columnCategories) {
                    ifIndex = table[0].indexOf(category);
                    useCategory = "__ALL";
                  } else {
                    ifIndex = table[0].indexOf(params.series[i].aggField);
                  }

                  let field = params.series[i].dataColumnY ? table[0].indexOf(params.series[i].dataColumnY) : table[0].indexOf(category);

                  Object.assign(calculationParams, {
                    field: field,
                    agg_field: ifIndex,
                    category: useCategory,
                    scalar:
                      params.formatting && params.formatting.scalar
                        ? params.formatting.scalar
                        : undefined,
                  });
                  return calculation(calculationParams, params.sourceTable, rows);
                });

              } else if (
                ["sumif", "countif", "meanif", "sum", "count"].includes(
                  params.series[i].aggregation
                )
              ) {
                params.xAxis.categories = categoriesUnique;
                //console.log(categoriesUnique);

                params.series[i].data = categoriesUnique.map((category) => {

                  // Conditions may be set as either TRUE/FALSE or 1/0 based on field type
                  let conditionOptions = [true, false];
                  if (rows[params.sourceTable].typeMap && rows[params.sourceTable].typeMap[category] && (rows[params.sourceTable].typeMap[category] === "BIGINT" || rows[params.sourceTable].typeMap[category] === "INT")) {
                    conditionOptions = [1, 0];
                  }
                  const conditionVal = params.series[i].negativeConditional
                    ? conditionOptions[1]
                    : conditionOptions[0];

                  let ifIndex = undefined;
                  let useCategory = category;
                  if (columnCategories) {
                    ifIndex = table[0].indexOf(category);
                    useCategory = "__ALL";
                  } else {
                    ifIndex = table[0].indexOf(params.series[i].conditionField);
                  }

                  let field = params.series[i].dataColumnY ? table[0].indexOf(params.series[i].dataColumnY) : table[0].indexOf(category);
                  //let field = table[0].indexOf(category) > -1 ? table[0].indexOf(category) : params.series[i].dataColumnY;

                  Object.assign(calculationParams, {
                    field: field,
                    if_field: ifIndex,
                    if_value: conditionVal,
                    category: useCategory,
                    scalar:
                      params.formatting && params.formatting.scalar
                        ? params.formatting.scalar
                        : undefined,
                  });
                  return calculation(calculationParams, params.sourceTable, rows);
                });
              } else {
                params.series[i].data = categoriesUnique.map((category) => {
                  //console.log("HERE", category)
                  calculationParams.category = category;
                  calculationParams.scalar =
                    params.formatting && params.formatting.scalar
                      ? params.formatting.scalar
                      : undefined;
                  return calculation(calculationParams, params.sourceTable, rows);
                });
              }
            }
          }

          if (!params.series[i].data) {
            // Pre-calced categories
            if (params.categoryColumnName) {
              new_data = Array(params.xAxis.categories.length);

              for (var j = 0; j < tableNoHeader.length; j++) {
                // if not aggregation function apply scalar
                let calculationParams = {};
                let catCol = table[0].indexOf(params.categoryColumnName);
                let ordered_index = params.xAxis.categories.indexOf(tableNoHeader[j][catCol]);
                calculationParams.value = tableNoHeader[j][yIndex];
                calculationParams.scalar = params.formatting && params.formatting.scalar ? params.formatting.scalar : undefined;

                if(ordered_index > -1) {
                  new_data[ordered_index] = calculation(calculationParams, params.sourceTable, rows);
                } else {
                  new_data[j] = calculation(calculationParams, params.sourceTable, rows);
                }
              }
            }
            // Pre-calced numerical series (live calc numerical aggregations are not supported)
            else {
              dataColumnX = params.series[i].dataColumnX;
              xIndex = table[0].indexOf(dataColumnX);
              for (var j = 0; j < tableNoHeader.length; j++) {
                let calculationParams = {};
                calculationParams.value = [tableNoHeader[j][xIndex], tableNoHeader[j][yIndex]];
                calculationParams.scalar = params.formatting && params.formatting.scalar ? params.formatting.scalar : undefined;
                new_data.push(calculation(calculationParams, params.sourceTable, rows));
              }
            }
            //console.log(dataColumnY, new_data)
            params.series[i].data = new_data;
          }

          //params.series[i].type = component_type;
        }
      }

      /*
      if (component_type === "line") {
        let new_data = params.series.map((figure, index) => {
          return {
            name: "Test",
            data: [10, 10],
          };
        });
        params.series = new_data;
      } */

      if (component_type === "heat") {
        let table = rows[params.sourceTable].data;

        let new_data = params.data.map((items, index) => {
          return items.map((item, j) => {
            item.field1 = table[0].indexOf(item.field1);
            item.field2 = table[0].indexOf(item.field2);

            return calculation(item, params.sourceTable, rows);
          });
        });
        params.data = new_data;
      }

      if (component_type === "map") {
        let new_data = [];

        let table = rows[params.sourceTable].data;
        let tableNoHeader = table.slice(1);
        let dataColumnX = "";
        let dataColumnY = params.series[0].dataColumnY;
        let xIndex = -1;
        let yIndex = table[0].indexOf(dataColumnY);

        for (var i = 0; i < all_states.length; i++) {
          let calculationParams = {};
          let useCategory = "__ALL";
          let ifIndex = table[0].indexOf("State");

          Object.assign(calculationParams, {
            operation: "sumif",
            field: yIndex,
            if_field: ifIndex,
            if_value: all_states[i],
            category: useCategory,
            scalar:
              params.formatting && params.formatting.scalar
                ? params.formatting.scalar
                : undefined,
          });
          //console.log(calculationParams);


          new_data.push([all_states[i], calculation(calculationParams, params.sourceTable, rows) === 0 ? .0000001 : calculation(calculationParams, params.sourceTable, rows)]);
        }
        delete(params.series);
        params.data = new_data;
      }

      if (component_type === "pie" || component_type === "circularProgress") {
        let new_data = params.data.map((item, index) => {
          return {
            name: item.name,
            y: calculation(item.y, params.sourceTable, rows),
          };
        });
        params.data = new_data;
      }

      if (component_type === "table") {
        params.columns.map(
          (column) =>
            (column["_fdOrder"] = rows[params.sourceTable].data[0].indexOf(
              column.field
            ))
        );
        params.rows = rows[params.sourceTable].data.slice(1);
        params.disableActions = !!state.preview;// disable actions if preview
      }

      if (component_type === "toggle" || component_type === "order_flow") {
        params.hasData = true;
      } else if (component_type !== "infobox") {
        params.hasData = rows[params.sourceTable].data.length > 1
      };

      // params.dashboardDict = dashboardDict;
      // params.updateDashboardDict = updateDashboardDict;
      // params.cleanTitle = cleanTitle;

      //console.log("PARAMS", params);
      return (
        <Grid item key={level + "-" + index} {...(item.size ? getGridItemSize(component_type, item.size): {}) }>
          {React.createElement(MyComponent, params)}
        </Grid>
      );
    }
  };

  const generateGrid = (top, level) => {
    let gridItems = [];
    for (var i = 0; i < top.length; i++) {
      gridItems.push(getGrid(top[i], i, level));
    }
    return gridItems;
  };

  return (
    <Grid container spacing={4}>
      <FDTopBar state={state} cancelAction={cancelAction} layout={layout} />
      <FDPreviewAlert state={state} layout={layout} />
      <FDMonetaryAndNextSteps state={state} />
      <FDAlertFeed
        // alerts={previewApp.alerts}
        hideAlerts={hideAlerts}
        preview={state.preview}
        developerAppUuid={state.thisDevApp?.uuid}
        highlightAlertUuid={state.thisAlert?.uuid}
        companyUuid={state.companyUuid}
      />
      {rows && (
        <Grid item xs={12} container spacing={2} className="main" sx={{ marginTop: "1em" }}>
          {generateGrid(layout.layout, 0)}
        </Grid>
      )}
    </Grid>
  )
}

export default DashboardView;