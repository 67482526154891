import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

export const config_options = {
  "suppress_alert": "suppress_alert",
  "hide_row": "hide_row",
  "status_tracker": "status_tracker",
  "create_entry": "create_entry",
  "send_email": "send_email"
}

const dashboardConfigsAdapter = createEntityAdapter({
  selectId: (config) => config.uuid,
});

// TODO: error states??? - how to set/resolve?

export const dashboardConfigsSlice = createSlice({
  name: "dashboardConfigs",
  initialState: dashboardConfigsAdapter.getInitialState(),
  reducers: {
    addConfigs: {
      reducer: dashboardConfigsAdapter.addMany,
      prepare: (configs) => {
        console.log("adding dashboardConfigs ", configs)
        // return {
        //   payload: {
        //     uuid: config.uuid,
        //     companyUuid: config.companyUuid,
        //     createdBy: config.createdBy,
        //     developerAppUuid: config.developerAppUuid,
        //     objectName: config.objectName,
        //     primaryKeyFieldList: config.primaryKeyFieldList,
        //     displayConfigTableRows: config.displayConfigTableRows,
        //     linkedTableForeignFieldMap: config.linkedTableForeignFieldMap,
        //     // what state pieces do we need?
        //     // create_or_modify_row
        //     updateArgs: null,
        //     refresh: false,
        //     error: {
        //       isErrored: true,
        //       message: ""
        //     }
        //   },
        // };
        const processedConfigs = configs.map(config => ({
          uuid: config.uuid,
          companyUuid: config.companyUuid,
          createdBy: config.createdBy,
          developerAppUuid: config.developerAppUuid,
          objectName: config.objectName,
          primaryKeyFieldList: config.primaryKeyFieldList,
          displayConfigTableRows: config.displayConfigTableRows,
          linkedTableForeignFieldMap: config.linkedTableForeignFieldMap,
          sourceTable: config.sourceTable,
          isUpdating: false,
          updateArgs: null,
          resetArgs: null,
          refresh: false,
          error: {
            isErrored: false,
            message: ""
          }
        }));
        // console.log("Adding displayConfigs", processedConfigs);
        return { payload: processedConfigs };
      },
    },
    updateConfig: {
      // set args for update
      reducer: dashboardConfigsAdapter.updateOne,
      prepare: (configUuid, args) => {
        console.log(configUuid + " displayConfig update args", args);
        return {
          payload: {
            id: configUuid,
            changes: {
              updateArgs: args,
              isUpdating: true
            }
          }
        }
      }
    },
    resetConfig: {
      // set reset args
      reducer: dashboardConfigsAdapter.updateOne,
      prepare: (configUuid, args) => {
        console.log(configUuid + " displayConfig reset args", args);
        return {
          payload: {
            id: configUuid,
            changes: {
              resetArgs: args,
              isUpdating: true
            }
          }
        }
      }
    },
    updateConfigTableRows: {
      // insert updated rows
      reducer: dashboardConfigsAdapter.updateOne,
      prepare: (configUuid, newDisplayConfigTableRows) => {
        // console.log(configUuid + " displayConfig new displayConfigTableRows");
        return {
          payload: {
            id: configUuid,
            changes: {
              displayConfigTableRows: newDisplayConfigTableRows,
              updateArgs: null,
              resetArgs: null,
              isUpdating: false
            }
          }
        }
      }
    },
    removeConfigs: {
      // remove all if user/sysadmin leaves dashboard from primary window (got there via email)
      reducer: dashboardConfigsAdapter.removeAll,
      prepare: () => {
        console.log("removing all displayConfigs")
        return ({ payload: {} })
      }
    }
  },
});

export const dashboardConfigsSelectors = dashboardConfigsAdapter.getSelectors((state) => {
  return state.dashboardConfigs;
});

// Action creators are generated for each case reducer function
export const {
  addConfigs,
  updateConfig,
  resetConfig,
  updateConfigTableRows,
  removeConfigs
} = dashboardConfigsSlice.actions;

export default dashboardConfigsSlice.reducer;