import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Constants from "../../Constants";
import DirectRequest from "../../API/requests/DirectRequest";
import {setCompany} from "../../store/alertsSysAdminSlice";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {setReadyForCustomer, setSysAdminPrimaryFeed, setSelectedTab as setSelectedSysAdminTab} from "../../store/alertsTabsSysAdminSlice";
import {RedirectLoader} from "./redirectUtils";

const SysAdminRedirectManager = ({ alertID, companyID, customerSubscriptionUuid }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyAlerts = useSelector((state) => state.alertsSysAdmin.alerts);
  const companyResolvedAlerts = useSelector((state) => state.alertsSysAdmin.resolvedAlerts);
  const allApps = useSelector((state) => state.appsSysAdmin.list);
  const [thisAlert, setThisAlert] = useState(null);

  useEffect(() => {
    if (companyAlerts.find(alert => alert.uuid === alertID)) {
      const tempAlert = companyAlerts.find(alert => alert.uuid === alertID);
      console.log("alert found in companyAlerts")
      if (tempAlert.flagAiRelevantStatus === "IRRELEVANT") {
        dispatch(setSelectedSysAdminTab('Irrelevant'))
      } else {
        dispatch(setSelectedSysAdminTab('Main'))
      }
      if (tempAlert.readyForCustomer) {
        dispatch(setReadyForCustomer(true))
      }
      if (!tempAlert.inPrimaryFeed) dispatch(setSysAdminPrimaryFeed(false))
      setThisAlert(tempAlert)
    } else if (companyResolvedAlerts.find(alert => alert.uuid === alertID)) {
      const tempResolvedAlert = companyResolvedAlerts.find(alert => alert.uuid === alertID);
      console.log("alert found in companyResolvedAlerts")
      dispatch(setSelectedSysAdminTab('Resolved'))
      if (tempResolvedAlert.readyForCustomer) {
        dispatch(setReadyForCustomer(true))
      }
      if (!tempResolvedAlert.inPrimaryFeed) dispatch(setSysAdminPrimaryFeed(false))
      setThisAlert(tempResolvedAlert)
    } else {
      console.warn("alert not found in companyAlerts or companyResolvedAlerts")
    }
  }, [companyAlerts, companyResolvedAlerts])

  useEffect(() => {
    if (thisAlert && allApps.length > 0) {
      console.log("apps", allApps, "thisAlert", thisAlert, "companyID", companyID, "customerSubscriptionUuid", customerSubscriptionUuid)
      const tempDevApp = allApps.find(app => app.uuid === thisAlert.developerAppUuid)
      const tempState = {
        customerSubscriptionUuid: customerSubscriptionUuid,
        viewApp: true,
        flagFilterComponentName: thisAlert.flagFilterComponentName,
        flagFilterInput: thisAlert.flagFilterInput,
        thisAlert: thisAlert,
        thisDevApp: tempDevApp,
        companyUuid: companyID,
        currentTab: 'Main',// doesn't matter - only consumed in AppRedirect (user or sysAdmin sent to redirect route via FeedTableButton's OpenDashboardButton)
        readyForCustomer: thisAlert.readyForCustomer, // doesn't matter - only consumed in AppRedirect (user or sysAdmin sent to redirect route via FeedTableButton's OpenDashboardButton)
        emailLink: true,
      }
      navigate(`/dashboard/${thisAlert.developerAppUuid}`, { state: tempState });
    }
  }, [thisAlert, allApps, customerSubscriptionUuid, companyID])

  return null;
}

export const SysAdminRedirect = ({ dashboardID, alertID, companyID }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companiesArgs, setCompaniesArgs] = useState({ url: Constants.SERVER_SYSADMIN_GET_COMPANIES_URL });
  const isCaptured = useSelector((state) => state.alertsSysAdmin.isCaptured);
  const [companySubscriptionsArgs, setCompanySubscriptionsArgs] = useState({ url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + companyID });
  const [customerSubscriptionUuid, setCustomerSubscriptionUuid] = useState("");

  const cancelAction = () => {
    navigate("/sysadmin_controls")
  }

  const handleCompanies = (res) => {
    console.log("handleCompanies companies", res);
    if (res) {
      const selectedCompany = res.find(company => company.uuid === companyID);
      console.log("selectedCompany", selectedCompany)
      dispatch(setCompany(selectedCompany));
    }
  }

  const handleCompanySubscriptions = (res) => {
    console.log("handleCompanySubscriptions subscriptions", res);
    if (res) {
      const tempSubscription = res.find(sub => sub.developerAppUuid === dashboardID);
      console.log("tempSubscription", tempSubscription)
      if (tempSubscription) {
        setCustomerSubscriptionUuid(tempSubscription.uuid);
      } else {
        console.warn("no subscription found for this app")
      }
    }
  }

  return (
    <>
      <DirectRequest
        requestArgs={companiesArgs}
        afterProcess={handleCompanies}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("SysAdminRedirect - sysAdmin link - get companies error", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("SysAdminRedirect - sysAdmin link- get companies catch error", err)}
      />
      <DirectRequest
        requestArgs={companySubscriptionsArgs}
        afterProcess={handleCompanySubscriptions}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("SysAdminRedirect - sysAdmin link - get company subscriptions error", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("SysAdminRedirect - sysAdmin link- get company subscriptions catch error", err)}
      />
      {isCaptured && customerSubscriptionUuid && (
        <SysAdminRedirectManager
          alertID={alertID}
          companyID={companyID}
          customerSubscriptionUuid={customerSubscriptionUuid}
        />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Grid item>
              <Button
                onClick={cancelAction}
                size="small"
                variant="contained"
                startIcon={<ChevronLeftIcon />}
              >
                SysAdmin alerts
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RedirectLoader/>
        </Grid>
      </Grid>
    </>
  );
}

export default SysAdminRedirect;