import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import { setSyncedNonEmptyTables } from "../../store/syncStatusSlice";
import { checkForSync } from "../../helpers/syncStatusHelpers";

export default function SyncedNonEmptyTablesRequest() {
  const dispatch = useDispatch();
  const hasSyncedAtLeastOnce = useSelector((state) => state.syncStatus.hasSyncedAtLeastOnce);
  const hasReceivedSyncedNonEmptyTables = useSelector((state) => state.syncStatus.hasReceivedSyncedNonEmptyTables);
  const [syncedNonEmptyTablesArgs, setSyncedNonEmptyTablesArgs] = useState({ url: Constants.SERVER_GET_SYNCED_NON_EMPTY_TABLES_URL });
  const handleRes = (res) => {
    console.log("SyncedNonEmptyTablesRequest handleRes -> res", res);
    dispatch(setSyncedNonEmptyTables(res));
  };
  const handleError = (err) => {
    console.log("SyncedNonEmptyTablesRequest handleError -> err", err);
  };
  return (
    <>
      {/* silenced for fivetran integration */}
      {/*{checkForSync(hasSyncedAtLeastOnce) && !hasReceivedSyncedNonEmptyTables && (*/}
      {/*  <DirectRequest requestArgs={syncedNonEmptyTablesArgs} afterProcess={handleRes} handleError={handleError} handleCatchError={handleError} />*/}
      {/*)}*/}
    </>
  );
}