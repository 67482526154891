import React, { useState, useRef, useEffect } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";
import {
  generateAlertUpdateArgs,
  generateAlertMessagesUpdateArgs,
  generateAlertUpdateNotificationArgs,
  generateMixpanelEvent,
  generateMixpanelViewAlertEvent,
  generateAiMessageNotificationArgs,
  generateGeneralAiMessageNotificationArgs,
  generateAiFixNotificationArgs,
  generateAlertEmailNotificationArgs,
  generateSysAdminAlertMessageArgs,
  generateSysAdminAlertOverrideArgs,
  generateSysAdminAlertReadyForCustomerArgs,
  generateSysAdminAlertToPrimaryFeedArgs,
  getFontWeightForRenderCell,
  formatMoneySaved,
  update_types
} from "./FeedTableUtils";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import DirectRequest from "../../API/requests/DirectRequest";
import { SERVER_POST_GENERAL_AI_MESSAGE_URL } from "../../Constants";
import {addMixpanelEvent} from "../../store/mixpanelTrackSlice";
import { useDispatch, useSelector } from "react-redux";
import {setTabsSysAdminDisabled} from "../../store/alertsTabsSysAdminSlice";
import {setTabsDisabled} from "../../store/alertsTabsSlice";
import {setSysAdminUpdateArgs, setUserUpdateArgs} from "../../store/alertsUpdateSlice";
import CircleIcon from '@mui/icons-material/Circle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {styled} from "@mui/material/styles";
import {setAlertNotificationArgs} from "../../store/alertsNotificationSlice";
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import {setUpdateAlertMessagesArgs} from "../../store/alertMessagesSlice";
import MessageIcon from '@mui/icons-material/Message';

const iconButtonStyle = {
  padding: "4px",
}

const aiButtonStyle = {
  borderRadius: "4px !important",
  padding: "4px",
}

export const RelevantButton = ({row, sysAdmin, companyUuid}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const handleRelevantClick = () => {
    // handleUpdateAlert(row, update_types.RELEVANT, null);
    const tempUpdateAlertArgs = sysAdmin ? generateAlertUpdateArgs(row, update_types.RELEVANT, null, companyUuid) : generateAlertUpdateArgs(row, update_types.RELEVANT, null);
    const tempMixpanelEvent = generateMixpanelEvent(row, update_types.RELEVANT, null);
    if (tempMixpanelEvent.event) dispatch(addMixpanelEvent(tempMixpanelEvent))// track event in mixpanel - triggers MixpanelTrack
    sysAdmin ? dispatch(setSysAdminUpdateArgs(tempUpdateAlertArgs)) : dispatch(setUserUpdateArgs(tempUpdateAlertArgs))// triggers AlertsUpdateRequest
    sysAdmin ? dispatch(setTabsSysAdminDisabled(true)) : dispatch(setTabsDisabled(true))// disable tabs (catch all term for buttons which alter FeedTable display - prevents click functionality while update is processing)
    if (!isInternal && !sysAdmin) {// if user is not internal and the button is being rendered without the sysAdmin prop, set the email notification - triggers AlertsNotificationRequest
      const tempAlertUpdateNotificationArgs = generateAlertUpdateNotificationArgs(row, update_types.RELEVANT, user);
      dispatch(setAlertNotificationArgs(tempAlertUpdateNotificationArgs))
    }
  }

  return (
    <>
      <Tooltip title={row.flagAiRelevantStatus === "RELEVANT" ? "Mark as unread" : "Mark as read"} followCursor={true} enterDelay={300} enterNextDelay={500}>
        <IconButton onClick={handleRelevantClick} sx={iconButtonStyle}>
          <CheckCircleOutlineIcon alt="Acknowledged" sx={{ color: `${row.flagAiRelevantStatus === "RELEVANT" ? theme.palette.primary.main : "inherit"}`}}/>
        </IconButton>
      </Tooltip>
    </>
  );
};

export const IrrelevantButton = ({row, sysAdmin, companyUuid}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const handleIrrelevantClick = () => {
    // handleUpdateAlert(row, update_types.IRRELEVANT, null);
    const tempUpdateAlertArgs = sysAdmin ? generateAlertUpdateArgs(row, update_types.IRRELEVANT, null, companyUuid) : generateAlertUpdateArgs(row, update_types.IRRELEVANT, null);
    const tempMixpanelEvent = generateMixpanelEvent(row, update_types.IRRELEVANT, null);
    if (tempMixpanelEvent.event) dispatch(addMixpanelEvent(tempMixpanelEvent))
    sysAdmin ? dispatch(setSysAdminUpdateArgs(tempUpdateAlertArgs)) : dispatch(setUserUpdateArgs(tempUpdateAlertArgs))
    sysAdmin ? dispatch(setTabsSysAdminDisabled(true)) : dispatch(setTabsDisabled(true))
    if (!isInternal && !sysAdmin) {
      const tempAlertUpdateNotificationArgs = generateAlertUpdateNotificationArgs(row, update_types.IRRELEVANT, user);
      dispatch(setAlertNotificationArgs(tempAlertUpdateNotificationArgs))
    }
  }

  return (
    <>
      <Tooltip title={row.flagAiRelevantStatus === "IRRELEVANT" ? "Undo remove" : "Remove"} followCursor={true} enterDelay={300} enterNextDelay={500}>
        <IconButton onClick={handleIrrelevantClick} sx={iconButtonStyle}>
          <VisibilityOffIcon alt="Removed" sx={{ color: `${row.flagAiRelevantStatus === "IRRELEVANT" ? theme.palette.primary.main : "inherit"}` }}/>
        </IconButton>
      </Tooltip>
    </>
  );
};

export const GeneralAiButton = ({ currentType, currentDate }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const currentTab = useSelector((state) => state.alertsTabs.selectedTab);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [message, setMessage] = useState("");
  const [generalAiMessageArgs, setGeneralAiMessageArgs] = useState(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = () => {
    const tempGeneralAiMessageArgs = {
      url: SERVER_POST_GENERAL_AI_MESSAGE_URL,
      method: "POST",
      body: JSON.stringify({ message: message })
    }
    setGeneralAiMessageArgs(tempGeneralAiMessageArgs)
    if (!isInternal) {
      const tempGeneralAiMessageNotificationArgs = generateGeneralAiMessageNotificationArgs(currentDate, currentType, currentTab, message, user)
      dispatch(setAlertNotificationArgs(tempGeneralAiMessageNotificationArgs))
    }
    const tempGeneralAiMessageMixpanelEvent = {
      event: "General AI message",
      additionalProperties: {
        message: message,
        currentDate: currentDate,
        currentType: currentType,
        currentTab: currentTab,
      }
    }
    dispatch(addMixpanelEvent(tempGeneralAiMessageMixpanelEvent))
    setOpen(false)
  }

  return (
    <>
      <DirectRequest
        requestArgs={generalAiMessageArgs}
        afterProcess={() => console.log("success posting general ai message")}
        handleError={(err) => console.log("error posting general ai message", err)}
        handleCatchError={(err) => console.log("catch error posting general ai message", err)}
      />
      <ButtonGroup>
        <Tooltip title={"AI training"} followCursor={true} enterDelay={300} enterNextDelay={500}>
          <IconButton onClick={handleToggle} sx={aiButtonStyle} id={"general-ai-button"}>
            <Typography
              ref={anchorRef}
              sx={{
                color: open ? theme.palette.primary.main : "rgba(0, 0, 0, 0.54)",
                fontWeight: 600,
                border: `2px solid ${open ? theme.palette.primary.main : "rgba(0, 0, 0, 0.54)"}`,
                borderRadius: "4px",
                padding: "2px 4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "40px",
                width: "40px"
              }}
              component={"div"}
            >
              <span style={{fontSize: '16px', lineHeight: "14px"}}>AI</span>
              <span style={{fontFamily: "Barlow Semi Condensed, Arial, sans-serif", fontSize: '11px', lineHeight: "12px", fontWeight: 500}}>Analyst</span>
            </Typography>
          </IconButton>
        </Tooltip>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom" id={"general-ai-input"}>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} sx={{ marginTop: ".5rem", width: "340px", backgroundColor: "white !important", borderRadius: "10px", padding: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
            <Grid item xs={12} sx={{ padding: "0px !important" }}>
              <FormControl sx={{ width: "100%"}}>
                <TextField
                  multiline
                  rows={3}
                  placeholder="Talk to Wise AI like it's an analyst on your team"
                  variant="outlined"
                  data-tut="reactour__description"
                  onChange={(event) => setMessage(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
              <Button onClick={handleSubmit} variant="contained" size={"small"} sx={{ width: "100%" }} disabled={!message}>Submit</Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export const AiButton = ({row}) => {
  const dispatch = useDispatch()
  const theme = useTheme();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [message, setMessage] = useState("");

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = () => {
    // handleUpdateAlert(row, update_types.AI, message);
    const tempUpdateAlertArgs = generateAlertUpdateArgs(row, update_types.AI, message);
    const tempUpdateAlertMessagesArgs = generateAlertMessagesUpdateArgs(row, message, user);
    const tempMixpanelEvent = generateMixpanelEvent(row, update_types.AI, message);
    const tempAiMessageEmailArgs = generateAiMessageNotificationArgs(row, message, user);
    if (tempMixpanelEvent.event) dispatch(addMixpanelEvent(tempMixpanelEvent))
    dispatch(setUserUpdateArgs(tempUpdateAlertArgs));
    dispatch(setUpdateAlertMessagesArgs(tempUpdateAlertMessagesArgs));
    if (!isInternal) dispatch(setAlertNotificationArgs(tempAiMessageEmailArgs)) // TODO: create alertsNotificationSlice and aiMessageNotificationRequest to handle the sending of the email
    dispatch(setTabsDisabled(true))
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={"AI training"} followCursor={true} enterDelay={300} enterNextDelay={500}>
        <IconButton onClick={handleToggle} sx={aiButtonStyle}>
          <Typography
            ref={anchorRef}
            sx={{
              color: open ? theme.palette.primary.main : "rgba(0, 0, 0, 0.54)",
              fontWeight: 600,
              border: `2px solid ${open ? theme.palette.primary.main : "rgba(0, 0, 0, 0.54)"}`,
              borderRadius: "4px",
              padding: "2px 4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "40px",
              width: "40px"
            }}
            component={"div"}
          >
            <span style={{fontSize: '16px', lineHeight: "14px"}}>AI</span>
            <span style={{fontFamily: "Barlow Semi Condensed, Arial, sans-serif", fontSize: '11px', lineHeight: "12px", fontWeight: 500}}>Analyst</span>
          </Typography>
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom" id={"alert-ai-input"}>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} sx={{ marginTop: ".5rem", width: "340px", backgroundColor: "white !important", borderRadius: "10px", padding: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
            <Grid item xs={12} sx={{ padding: "0px !important" }}>
              <FormControl sx={{ width: "100%"}}>
                <TextField
                  multiline
                  rows={3}
                  placeholder={`Talk to Wise AI like it's an analyst on your team`}
                  variant="outlined"
                  data-tut="reactour__description"
                  onChange={(event) => setMessage(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
              <Button onClick={handleSubmit} variant="contained" size={"small"} sx={{ width: "100%" }} disabled={!message}>Submit</Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export const AiFixButton = ({row}) => {
  const dispatch = useDispatch()
  const theme = useTheme();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const Message = ({ success }) => {
    return !success ? (
      <>
        <Typography sx={{ marginBottom: "10px"}}>
          <strong>AI Fix</strong> allows WiseLayer’s AI agent to fully fix any issue for you. Customers love this free new tool, because the time-to-value is nearly instant!
        </Typography>
        <Typography sx={{ marginBottom: "10px"}}>
          To try <strong>AI Fix</strong>, click the button below. A WiseLayer team member will show you how it works, collect some information from you, and then enable it on your account.
        </Typography>
      </>
    ) : (
      <Typography sx={{ height: "146px" }}>Thank you, a WiseLayer team member will reach out to you shortly about <strong>AI Fix</strong></Typography>
    )
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = () => {
    if (!isInternal) {
      const tempAiFixNotificationArgs = generateAiFixNotificationArgs(row, user);
      dispatch(setAlertNotificationArgs(tempAiFixNotificationArgs))
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSuccess(true);
    }, 2000)
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setOpen(false);
        setSuccess(false);
      }, 3000)
    }
  }, [success])

  return (
    <>
      <Tooltip title={"AI fix"} followCursor={true} enterDelay={300} enterNextDelay={500}>
        <IconButton onClick={handleToggle} sx={aiButtonStyle}>
          <Typography
            ref={anchorRef}
            sx={{
              color: open ? theme.palette.primary.main : "rgba(0, 0, 0, 0.54)",
              fontWeight: 600,
              border: `2px solid ${open ? theme.palette.primary.main : "rgba(0, 0, 0, 0.54)"}`,
              borderRadius: "4px",
              padding: "2px 4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "40px",
              width: "40px"
            }}
            component={"div"}
          >
            <span style={{fontSize: '16px', lineHeight: "14px"}}>AI</span>
            <span style={{fontFamily: "Barlow Semi Condensed, Arial, sans-serif", fontSize: '11px', lineHeight: "12px", fontWeight: 500}}>Fix</span>
          </Typography>
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom">
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} sx={{ marginTop: ".5rem", width: "340px", backgroundColor: "white !important", borderRadius: "10px", padding: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
            <Grid item xs={12} sx={{ padding: "0px !important" }}>
              <Message success={success}/>
            </Grid>
            <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
              <Button
                onClick={handleSubmit}
                disabled={loading || success}
                startIcon={loading ? <CircularProgress size="1rem"/> : success ? <CheckIcon sx={{ color: theme.palette.primary.main }}/> : null}
                variant="contained"
                size={"small"}
                sx={{ width: "100%" }}
              >
                Try AI fix
              </Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export const MailButton = ({row}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const handleMailClick = () => {
    let email = "";
    let subject = "Question requiring your expertise";
    let body = `As you may know, we use WiseLayer’s finance AI tool to support the financial efficiency of our company. It recently notified us about an item, summarized below. So that our team can better understand this issue, would you please provide any relevant information or context about the summarized item? Thank you!\n\n AI summary: ${row.appNameDisplay} - ${row.flagDescription}\n\nLink to issue summary: ${window.location.origin}/redirect/${row.developerAppUuid}/${row.uuid}`;
    const mailtoLink  = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    // Open the link in a new tab
    window.open(mailtoLink, '_blank');
    if (!isInternal) {
      const tempAlertEmailNotificationArgs = generateAlertEmailNotificationArgs(row, user);
      dispatch(setAlertNotificationArgs(tempAlertEmailNotificationArgs))
    }
  }
  return (
    <>
      <Tooltip title={"Email summary"} followCursor={true} enterDelay={300} enterNextDelay={500}>
        <IconButton onClick={handleMailClick} sx={iconButtonStyle}>
          <ForwardToInboxIcon alt="Forward" sx={{color: "inherit"}}/>
        </IconButton>
      </Tooltip>
    </>
  )
}

export const AppNameDisplayCell = ({row, currentTab, sysAdmin, preview}) => {
  return (
    <span style={{
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      textWrap: "wrap",
      fontWeight: getFontWeightForRenderCell(row, currentTab, sysAdmin, preview),
    }}>{row.appNameDisplay}</span>
  )
}

export const CustomDescriptionTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 1000,
  },
});

export const OpenDashboardButton = ({row, apps, subscribedApps, companyUuid, currentTab, readyForCustomer, dashboardView, sysAdmin, preview}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const openDashboard = (row) => {
    const thisDevApp = apps.find(app => app.uuid === row.developerAppUuid);
    const customerSubscriptionUuid = subscribedApps.find(app => app.developerAppUuid === row.developerAppUuid).uuid;
    localStorage.setItem("dashboardState", JSON.stringify({
      customerSubscriptionUuid: customerSubscriptionUuid,
      viewApp: true,
      flagFilterComponentName: row.flagFilterComponentName,
      flagFilterInput: row.flagFilterInput,
      thisAlert: row,
      thisDevApp: thisDevApp,
      companyUuid: companyUuid,
      currentTab: currentTab,
      readyForCustomer: readyForCustomer
    }))
    window.open(`${window.location.origin}/redirect/${row.developerAppUuid}`, '_blank');
    dispatch(addMixpanelEvent(generateMixpanelViewAlertEvent(row)));
  }
  return (
    <CustomDescriptionTooltip title={sysAdmin ? row.flagDescription : ""} followCursor>
      <Typography
        variant="link"
        sx={{
          ...(!dashboardView && currentTab !== 'Resolved' && {
            color: theme.palette.primary.main,
            "&:hover": { cursor: "pointer" },
          }),
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
          textWrap: "wrap",
          fontWeight: getFontWeightForRenderCell(row, currentTab, sysAdmin, preview),
        }}
        onClick={(!dashboardView && currentTab !== 'Resolved') ? () => openDashboard(row) : null}
      >
        {row["flagDescription"]}
      </Typography>
    </CustomDescriptionTooltip>
  )
}

export const MoneySavedCell = ({row, currentTab, sysAdmin, preview}) => {
  const primaryFeed = useSelector(state => sysAdmin ? state.alertsTabsSysAdmin.primaryFeed : state.alertsTabs.primaryFeed)
  // old expression: sysAdmin ? row.moneySaved : getMoneySaved(row.moneySaved)
  return (
      <Typography sx={{ fontWeight: getFontWeightForRenderCell(row, currentTab, sysAdmin, preview) }}>
        {formatMoneySaved(primaryFeed, sysAdmin, row.moneySaved)}
      </Typography>
  )
}

export const ReferenceDescriptionCell = ({row, currentTab, sysAdmin, preview}) => {
  return (
    <Typography
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        textWrap: "wrap",
        fontWeight: getFontWeightForRenderCell(row, currentTab, sysAdmin, preview)
      }}
    >
      {row.referenceDescription}
    </Typography>
  )
}

export const AiMessageCell = ({row}) => {
  return (
    <>
      {row.flagAiMessage && row.flagAiMessage !== "SysAdmin: No message provided" && (
        <Tooltip title={row.flagAiMessage} followCursor>
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              textWrap: "wrap",
            }}
          >{row.flagAiMessage}</Typography>
        </Tooltip>
      )}
    </>
  )
}

export const LastActionTakenCell = ({row}) => {
  return (
    <>
      {row.lastActionTaken && (
        <Tooltip title={row.lastActionTaken} followCursor>
          <Typography>{row.lastActionTaken}</Typography>
        </Tooltip>
      )}
    </>
  )
}

export const FlagOverrideAndShowAnywayCell = ({row}) => {
  const theme = useTheme();
  return (
    <>
      {row.flagOverrideAndShowAnyway && <CircleIcon sx={{ color: theme.palette.primary.main }}/>}
    </>
  )
}

export const SysAdminMessageButton = ({row, companyUuid, currentTab}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = () => {
    // handleSysAdminMessage(row, message);
    const tempSysAdminMessageArgs = generateSysAdminAlertMessageArgs(row, message, companyUuid);
    dispatch(setSysAdminUpdateArgs(tempSysAdminMessageArgs))
    dispatch(setTabsSysAdminDisabled(true))
    setOpen(false);
  }

  return (
    <>
      <Tooltip title={"Edit sysadmin message"} followCursor>
        <IconButton ref={anchorRef} onClick={handleToggle} disabled={currentTab === 'Resolved'} sx={iconButtonStyle}>
          <MessageIcon alt="SysAdmin message"/>
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom" sx={{ zIndex: 1 }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} sx={{ marginTop: ".5rem", width: "300px", backgroundColor: "white !important", borderRadius: "10px", padding: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
            <Grid item xs={12} sx={{ padding: "0px !important" }}>
              <FormControl sx={{ width: "100%"}}>
                <TextField
                  multiline
                  rows={3}
                  placeholder={"Enter a new sysAdmin message"}
                  variant="outlined"
                  data-tut="reactour__description"
                  onChange={(event) => setMessage(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
              <Button onClick={handleSubmit} variant="contained" size={"small"} sx={{ width: "100%" }}>Submit</Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export const SysAdminReadyForCustomerButton = ({row, companyUuid}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleReadyForCustomerClick = () => {
    handleToggle();
  }

  const handleReadyForCustomerSubmit = () => {
    // handleSysAdminReadyForCustomer(row);
    const tempSysAdminReadyForCustomerArgs = generateSysAdminAlertReadyForCustomerArgs(row, companyUuid);
    dispatch(setSysAdminUpdateArgs(tempSysAdminReadyForCustomerArgs));
    dispatch(setTabsSysAdminDisabled(true))
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={!row.readyForCustomer ? "Release to customer" : "Unrelease to customer"} followCursor={true} enterDelay={300} enterNextDelay={500}>
        <IconButton onClick={handleReadyForCustomerClick} sx={iconButtonStyle}>
          {row.readyForCustomer && <LockOpenIcon ref={anchorRef} alt="unrelease" sx={{color: `${open ? theme.palette.primary.main : "inherit"}`}}/>}
          {!row.readyForCustomer && <LockIcon ref={anchorRef} alt="release" sx={{color: `${open ? theme.palette.primary.main : "inherit"}`}}/>}
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom" sx={{ zIndex: 1 }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} sx={{ marginTop: ".5rem", /*width: "300px",*/ backgroundColor: "white !important", borderRadius: "10px", padding: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
            <Grid item xs={12} sx={{ padding: "0px !important"}}>
              <Button onClick={handleReadyForCustomerSubmit} variant="contained" size={"small"} sx={{ width: "100%" }}>{!row.readyForCustomer ? "Confirm Release" : "Confirm Unrelease"}</Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export const SysAdminPrimaryFeedButton = ({row, companyUuid}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToPrimaryFeedClick = () => {
    handleToggle();
  }

  const handleToPrimaryFeedSubmit = () => {
    const tempSysAdminToPrimaryFeedArgs = generateSysAdminAlertToPrimaryFeedArgs(row, companyUuid);
    dispatch(setSysAdminUpdateArgs(tempSysAdminToPrimaryFeedArgs));
    dispatch(setTabsSysAdminDisabled(true))
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={!row.inPrimaryFeed ? "Add to primary feed" : "Remove from primary feed"} followCursor={true} enterDelay={300} enterNextDelay={500}>
        <IconButton onClick={handleToPrimaryFeedClick} sx={iconButtonStyle}>
          {row.inPrimaryFeed && <FolderSpecialIcon ref={anchorRef} alt="fromPrimaryFeed" sx={{color: theme.palette.primary.main}}/>}
          {!row.inPrimaryFeed && <FolderSpecialIcon ref={anchorRef} alt="toPrimaryFeed" sx={{color: `${open ? theme.palette.primary.main : "inherit"}`}}/>}
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom" sx={{ zIndex: 1 }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} sx={{ marginTop: ".5rem", /*width: "300px",*/ backgroundColor: "white !important", borderRadius: "10px", padding: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
            <Grid item xs={12} sx={{ padding: "0px !important"}}>
              <Button onClick={handleToPrimaryFeedSubmit} variant="contained" size={"small"} sx={{ width: "100%" }}>{!row.inPrimaryFeed ? "Add to primary feed" : "Remove from primary feed"}</Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const CopyUserLink = ({row}) => {
  const [copyButtonText, setCopyButtonText] = useState("Copy user link");
  const handleCopyUserLink = (row) => {
    navigator.clipboard.writeText(`${row.appNameDisplay} - ${row.flagDescription}\n${window.location.origin}/redirect/${row.developerAppUuid}/${row.uuid}`);
    setCopyButtonText("Copied!");
    setTimeout(() => {
      setCopyButtonText("Copy user link");
    }, 2000);
  }
  return (
    <Grid item xs={12} sx={{ padding: "0px !important"}}>
      <Button onClick={() => handleCopyUserLink(row)} disabled={!row.readyForCustomer} variant="contained" size={"small"} sx={{ width: "100%" }}>{copyButtonText}</Button>
    </Grid>
  )
}

const CopySysAdminLink = ({row, companyUuid}) => {
  const [copyButtonText, setCopyButtonText] = useState("Copy sysAdmin link");
  const handleCopySysAdminLink = (row) => {
    navigator.clipboard.writeText(`${window.location.origin}/redirect/${row.developerAppUuid}/${row.uuid}/${companyUuid}`);
    setCopyButtonText("Copied!");
    setTimeout(() => {
      setCopyButtonText("Copy sysAdmin link");
    }, 2000);
  }
  return (
    <Grid item xs={12} sx={{ padding: "0px !important", marginTop: "1rem"}}>
      <Button onClick={() => handleCopySysAdminLink(row)} variant="outlined" size={"small"} sx={{ width: "100%" }}>{copyButtonText}</Button>
    </Grid>
  )
}

export const SysAdminCopyLinkButton = ({row, companyUuid, currentTab}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={currentTab !== 'Resolved' ? "Copy alert link" : ""} followCursor={true} enterDelay={300} enterNextDelay={500}>
        <IconButton ref={anchorRef} onClick={handleToggle} disabled={currentTab === 'Resolved'} sx={iconButtonStyle}>
          <ContentCopyIcon alt="Copy link"/>
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom" sx={{ zIndex: 1 }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} sx={{ marginTop: ".5rem", width: "300px", backgroundColor: "white !important", borderRadius: "10px", padding: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
            <CopyUserLink row={row}/>
            <Grid item xs={12}></Grid>
            <CopySysAdminLink row={row} companyUuid={companyUuid}/>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export const SysAdminOverrideButton = ({row, companyUuid}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleOverrideClick = () => {
    handleToggle();
  }
  const handleOverrideSubmit = () => {
    // handleSysAdminOverride(row);
    const tempSysAdminOverrideArgs = generateSysAdminAlertOverrideArgs(row, companyUuid);
    dispatch(setSysAdminUpdateArgs(tempSysAdminOverrideArgs));
    dispatch(setTabsSysAdminDisabled(true))
    setOpen(false)
  };

  return (
    <>
      <Tooltip title={"Override IRRELEVANT status"} followCursor={true}>
        <IconButton onClick={handleOverrideClick} disabled={row.flagArchive} sx={iconButtonStyle}>
          <VisibilityIcon ref={anchorRef} alt="Override" sx={{color: `${open ? theme.palette.primary.main : "inherit"}`}}/>
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom" sx={{ zIndex: 1 }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} sx={{ marginTop: ".5rem", /*width: "300px",*/ backgroundColor: "white !important", borderRadius: "10px", padding: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
            <Grid item xs={12} sx={{ padding: "0px !important"}}>
              <Button onClick={handleOverrideSubmit} variant="contained" size={"small"} sx={{ width: "100%" }}>Confirm Override</Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
};

export const FeedTableButtonPicker = ({row, sysAdmin, companyUuid, currentTab}) => {
  const pickButtons = (row) => {
    // resolved alerts should display their relevant status/buttons to sysadmins (but buttons will be disabled, this is handled via the return statement's div)
    if (currentTab === "Resolved") {
      if (sysAdmin) {
        return (
          <>
            <RelevantButton row={row} sysAdmin={sysAdmin} companyUuid={companyUuid}/>
            <IrrelevantButton row={row} sysAdmin={sysAdmin} companyUuid={companyUuid}/>
          </>
        )
      } else {
        return (
          <></>
        )
      }
      // otherwise if irrelevant and sysadmin, display nothing here (override button will be displayed in the actions column -> FeedTableActionsButtonPicker)
    } else if (row.flagAiRelevantStatus === "IRRELEVANT" && sysAdmin) {
      return (
        <SysAdminOverrideButton row={row} companyUuid={companyUuid}/>
      )
      // otherwise just display the Relevant/Irrelevant buttons
    } else {
      return (
        <>
          <RelevantButton row={row} sysAdmin={sysAdmin} companyUuid={companyUuid}/>
          <IrrelevantButton row={row} sysAdmin={sysAdmin} companyUuid={companyUuid}/>
        </>
      )
    }
  };
  return (
    <div style={sysAdmin && currentTab === "Resolved" ? {pointerEvents: 'none'}:{}}>
      {pickButtons(row)}
    </div>
  )
}

export const FeedTableActionsButtonPicker = ({row, sysAdmin, companyUuid, currentTab}) => {
  const pickActionButtons = (row) => {
    if (sysAdmin) {
      return (
        <>
          <SysAdminReadyForCustomerButton row={row} companyUuid={companyUuid}/>
          <SysAdminPrimaryFeedButton row={row} companyUuid={companyUuid}/>
          <SysAdminCopyLinkButton row={row} companyUuid={companyUuid} currentTab={currentTab}/>
          <SysAdminMessageButton row={row} companyUuid={companyUuid} currentTab={currentTab}/>
        </>
      )
    } else {
      return (
        <>
          <AiButton row={row}/>
          <AiFixButton row={row}/>
          <MailButton row={row}/>
        </>
      )
    }
  };
  return (
    <div>
      {pickActionButtons(row)}
    </div>
  )
};