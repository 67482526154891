import { createSlice } from '@reduxjs/toolkit';
import { config_options} from "./dashboardConfigsSlice";

const initialDashboardTransformState = {
  transformations: {
    // holds tables as keys with their respective displayConfigTableRows
    // table dict of displayConfig__configUuid__configRowUuid: { valueMap: { ... }, ...other properties }
  }
}

export const displayConfigTransformPayload = (configUuid, displayConfigTableRows, sourceTable) => {
  return {
    configUuid: configUuid,
    displayConfigTableRows: displayConfigTableRows,
    sourceTable: sourceTable
  }
}

const deleteDisplayConfigTransforms = (configUuid, transforms) => {
  // console.log("start deleteDisplayConfigFilters")
  // console.log("configUuid ", configUuid);
  const transformsClone = {...transforms};
  const tables = Object.keys(transforms);
  // console.log("tables ", tables)
  for (let i = 0; i < tables.length; i++) {
    const tableTransforms = Object.keys(transforms[tables[i]]);
    console.log("tableTransforms", tableTransforms)
    // console.log("tableFilters ", tableFilters)
    const transformsForThisConfig = tableTransforms.filter((key) => key.includes(`displayConfig__${configUuid}`));
    console.log("transformsForThisConfig", transformsForThisConfig);
    // console.log("filtersForThisConfig ", filtersForThisConfig)
    if (transformsForThisConfig.length > 0) {
      transformsForThisConfig.forEach((transforms) => {
        // console.log("deleting ->", filter, filtersClone[tables[i]][filter]);
        delete transformsClone[tables[i]][transforms];
      })
    }
  }
  // console.log("filtersClone", filtersClone);
  // console.log("end deleteDisplayConfigFilters")
  return transformsClone
}

// displayConfig__configUuid__rowUuid: { valueMap: {...}, displayConfigOptions: { hide_row: true } }
const addDisplayConfigTransforms = (configUuid, transforms, sourceTable, displayConfigTableRows, objectName) => {
  const transformsClone = JSON.parse(JSON.stringify(transforms));
  // you need to go row by row
  displayConfigTableRows.forEach((displayConfigTableRow) => {
    /*
    ::: displayConfigRow :::
    String uuid;                // this is constant - we could do a thing where for each row we check each table for displayConfigRow__configRowUuid
    String companyUuid;
    String createdBy;
    Map<String, Object> primaryKeyValueMap;
    Map<String, Map<String, Object>> linkedTableForeignValueMaps;
    Map<String, Object> displayConfigOptions;
    */
    const { uuid, primaryKeyValueMap, displayConfigOptions/*, linkedTableForeignValueMaps*/ } = displayConfigTableRow;
    transformsClone[sourceTable][`displayConfig__${configUuid}__${uuid}`] = { valueMap: primaryKeyValueMap, displayConfigOptions: displayConfigOptions, objectName: objectName }
    // if (linkedTableForeignValueMaps) {
    //   const linkedTables = Object.keys(linkedTableForeignValueMaps)
    //   if (linkedTables.length > 0) {
    //     linkedTables.forEach(linkedTable => {
    //       transformsClone[linkedTable][`displayConfig__${configUuid}__${uuid}`] = { valueMap: linkedTableForeignValueMaps[linkedTable], displayConfigOptions: displayConfigOptions, objectName: objectName }
    //     })
    //   }
    // }
  })
  return transformsClone;
}

export const dashboardTransformSlice = createSlice({
  name: "dashboardTransform",
  initialState: initialDashboardTransformState,
  reducers: {
    setUpTransforms: (state, action) => {
      console.log("dashboardTransformSlice - setUpTransform", action.payload);
      /*
      what would i need here to plug in replacement values??
        A) table to transform
        B) type of transformation: suppress_alert, status_tracker

      suppress_alert:
      suppressAlertConfig.alertFlagColList
      -> will also need displayConfigTableRows

      status_tracker:
      statusTrackerConfig.allowedStatusList
      statusTrackerConfig.defaultStatus
      statusTrackerConfig.overrideColName
      statusTrackerConfig.overrideColStatus
      -> will also need displayConfigTableRows




      */
      const { tables, dashboardJson } = action.payload;
      const tableNames = Object.keys(tables);
      const newTransformations = {};
      tableNames.forEach(tableName => {
        newTransformations[tableName] = {}
      })
      // check dashboardJson.components for type === "table" and displayConfigEnabled and then insert keys "suppress_alert": true, "status_tracker": true
      // these simple key/value pairs are crucial for transformRows in DashboardRowsManager to work
      Object.entries(dashboardJson.components).forEach(([objectName, component]) => {
        if (component.type === "table" && component.suppressAlertConfig) {
          newTransformations[component.sourceTable][config_options.suppress_alert] = true;
        }
        if (component.type === "table" && component.statusTrackerConfig) {
          newTransformations[component.sourceTable][config_options.status_tracker] = true;
        }
      })
      state.transformations = newTransformations;
    },
    setDisplayConfigTransforms: (state, action) => {
      console.log("setDisplayConfigTransforms", action.payload);
      const { configUuid, displayConfigTableRows, sourceTable, objectName } = action.payload;
      const currentTransforms = JSON.parse(JSON.stringify(state.transformations));
      // console.log("current filters", currentTransforms);
      const cleanedTransforms = deleteDisplayConfigTransforms(configUuid, currentTransforms);
      // console.log("cleanedTransforms", cleanedTransforms);
      const updatedTransforms = addDisplayConfigTransforms(configUuid, cleanedTransforms, sourceTable, displayConfigTableRows, objectName);
      console.log("transformations", updatedTransforms);
      state.transformations = updatedTransforms;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setUpTransforms,
  setDisplayConfigTransforms
} = dashboardTransformSlice.actions;

export default dashboardTransformSlice.reducer;