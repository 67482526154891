import React, { useState, useEffect } from "react";
import DirectRequest from "../../API/requests/DirectRequest";
import Grid from "@mui/material/Grid";
import * as Constants from "../../Constants";
import Typography from "@mui/material/Typography";
import {keyframes, styled} from "@mui/system";
import CircleIcon from "@mui/icons-material/Circle";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import { RunStatus } from "./SysAdminDatabricksRuns";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from '@mui/icons-material/Launch';

const SummaryValue = ({ valueTitle, value }) => {
  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item>
        <Typography>{valueTitle}:</Typography>
      </Grid>
      <Grid item>
        <Typography><strong>{value}</strong></Typography>
      </Grid>
    </Grid>
  )
}

const SummaryWindow = ({ summaryWindowTitle, summaryWindow }) => {
  const valuesToDisplay = ["rowsProcessed", "cellsProcessed", "alertCount", "minutesSaved", "moneySaved"];
  return (
    <Grid item xs={3} container spacing={2}>
      <Grid item xs={12}>
        <Typography><strong>{summaryWindowTitle}</strong></Typography>
      </Grid>
      {valuesToDisplay.map((valueKey) => {
        return (
          <SummaryValue
            valueTitle={valueKey}
            value={summaryWindow[valueKey]}
          />
        )
      })}
    </Grid>
  )
}
const SysAdminSummaryMetrics = ({selectedCompany}) => {
  const [companySummaryMetricsArgs, setCompanySummaryMetricsArgs] = useState(null);
  const [companySummaryMetrics, setCompanySummaryMetrics] = useState(null);
  const summaryWindows = ["today", "last7Days", "last30Days", "fullHistory"];
  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      if (selectedCompany.name) console.log("selected company", selectedCompany.name);
      setCompanySummaryMetricsArgs({ url: Constants.SERVER_GET_SYSADMIN_SUMMARY_METRICS_URL + selectedCompany.uuid })
      setCompanySummaryMetrics(null)
    }
  }, [selectedCompany])

  const handleCompanySummaryMetrics = (res) => {
    console.log("company summaryMetrics", res?.summary)
    if (res && res.summary) {
      setCompanySummaryMetrics(res.summary)
    }
  }

  return (
    <>
      <DirectRequest
        requestArgs={companySummaryMetricsArgs}
        afterProcess={handleCompanySummaryMetrics}
      />
      <Grid item xs={12} container spacing={2}>
        {companySummaryMetrics && (
          <Grid item xs={12}>
            <Typography variant={"h6"}>Summary metrics</Typography>
          </Grid>
        )}
        {companySummaryMetrics && summaryWindows.map((summaryWindow, index) => {
          return (
            <SummaryWindow
              summaryWindowTitle={summaryWindow}
              summaryWindow={companySummaryMetrics[summaryWindow]}
              key={index}
            />
          )
        })}
      </Grid>
    </>
  )
}

const CustomConnectorTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const blink = keyframes`
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
`;

const withBlinkingAnimation = (Component, color) => styled(Component)(({ theme }) => ({
  color: color,
  animation: `${blink} 1s linear infinite`,
  fontSize: "1rem",
  marginLeft: "6px"
}));

// use the HOC to create a blinking CircleIcon
const SyncingIcon = withBlinkingAnimation(CircleIcon, 'green');
const SucceededIcon = () => <CircleIcon color={'success'} sx={{ fontSize: "1rem", marginLeft: "6px" }}/>;
const FailedIcon = () => <CircleIcon color={'error'} sx={{ fontSize: "1rem", marginLeft: "6px" }}/>;

const getMostRecentKey = (succeeded_at, failed_at) => {
  // Check if both succeeded_at and failed_at are null or not present
  if (succeeded_at === null && failed_at === null) {
    return null; // Neither succeeded nor failed
  }

  // Check if succeeded_at is null
  if (succeeded_at === null) {
    return 'failed_at'; // Only failed_at is available and not null
  }

  // Check if failed_at is null
  if (failed_at === null) {
    return 'succeeded_at'; // Only succeeded_at is available and not null
  }

  // Both succeeded_at and failed_at are not null, parse them into Date objects
  const succeededDate = new Date(succeeded_at);
  const failedDate = new Date(failed_at);

  // Compare the two dates
  if (failedDate > succeededDate) {
    return 'failed_at'; // Failure is more recent
  } else {
    return 'succeeded_at'; // Success is more recent or both are at the same time
  }
}

const getStatusLight = (connector) => {
  if (connector.status["sync_state"] === "syncing") {
    return <SyncingIcon/>
  }
  const resultOfCheck = getMostRecentKey(connector["succeeded_at"], connector["failed_at"]);
  if (!resultOfCheck || resultOfCheck === 'failed_at') {
    return <FailedIcon/>;
  } else {
    return <SucceededIcon/>;
  }
}

const StatusLight = ({ connector }) => {
  // this component needs to first check status.sync_state
  // if "syncing" flashing green
  // else:
  //  if connector.succeeded_at is more recent than connector.failed_at solid green
  //  if connector.failed_at is more recent than connector.succeeded_at solid red
  return getStatusLight(connector);
}

const ConnectorTitle = ({ connector }) => {
  return (
      <Grid item xs={12} container spacing={1} alignItems={"center"} sx={{ marginTop: "1px" }}>
        <StatusLight connector={connector}/>
        <Typography sx={{ marginLeft: "3px"}}><strong>{Constants.supported_integrations.find((integration) => integration.name === connector.service).displayName}</strong></Typography>
        <CustomConnectorTooltip title={<pre>{JSON.stringify(connector, null, 2)}</pre>}>
          <SearchIcon sx={{marginLeft: "3px", marginTop: "1px", fontSize: "0.75rem"}}/>
        </CustomConnectorTooltip>
      </Grid>
  )
}

const SucceededOrFailed = ({ connector }) => {
  const resultOfCheck = getMostRecentKey(connector["succeeded_at"], connector["failed_at"]);
  if (!resultOfCheck) {
    return (
      <Grid item xs={12} container spacing={1}>
        <Grid item>
          <Typography>No success or failure values</Typography>
        </Grid>
      </Grid>
    );
  }
  if (resultOfCheck === 'failed_at') {
    return (
      <Grid item xs={12} container spacing={1}>
        <Grid item>
          <Typography>Failed at:</Typography>
        </Grid>
        <Grid item>
          <Typography><strong>{connector["failed_at"]}</strong></Typography>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid item xs={12} container spacing={1}>
        <Grid item>
          <Typography>Succeeded at:</Typography>
        </Grid>
        <Grid item>
          <Typography><strong>{connector["succeeded_at"]}</strong></Typography>
        </Grid>
      </Grid>
    )
  }
}

const SetupState = ({ connector }) => {
  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item>
        <Typography>Setup state:</Typography>
      </Grid>
      <Grid item>
        <Typography><strong>{connector.status["setup_state"]}</strong></Typography>
      </Grid>
    </Grid>
  )
}

const SyncedTables = ({ connector, selectedCompany }) => {
  const [syncedTables, setSyncedTables] = useState(null);
  const [syncedTablesArgs, setSyncedTablesArgs] = useState(null);
  useEffect(() => {
    if (connector && connector.id && selectedCompany && selectedCompany.uuid) {
      setSyncedTablesArgs({ url: Constants.SERVER_SYSADMIN_GET_CONNECTOR_SYNCED_TABLES_URL + connector.id + "/list/" + selectedCompany.uuid });
      setSyncedTables(null);
    }
  }, [connector, selectedCompany])

  const handleSyncedTables = (res) => {
    console.log("synced tables", res)
    if (res) {
      setSyncedTables(res.tables)
    }
  }

  return (
    <>
      <DirectRequest
        requestArgs={syncedTablesArgs}
        afterProcess={handleSyncedTables}
      />
      {syncedTables && (
        <Grid item xs={12} container spacing={1} sx={{ marginTop: "1px"}}>
          <Grid item xs={12}container spacing={1} alignItems={"center"}>
            <Typography sx={{marginLeft: "8px"}}>Synced tables:</Typography>
            <CustomConnectorTooltip title={<pre>{JSON.stringify(syncedTables, null, 2)}</pre>}>
              <SearchIcon sx={{marginLeft: "3px", marginTop: "1px", fontSize: "0.75rem"}}/>
            </CustomConnectorTooltip>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                height: "100px",
                overflow: "auto",
                border: "1px solid grey",
              }}
            >
              {syncedTables.map((table, index) => (
                <Box key={index} sx={{ width: "100%", marginTop: "3px", marginBottom: "3px" }}>
                  <Typography sx={{marginLeft: "3px"}}>{table}</Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}

const FivetranConnector = ({ connector, selectedCompany }) => {
  return (
      <Grid item xs={3} container spacing={2}>
        <ConnectorTitle connector={connector}/>
        <SucceededOrFailed connector={connector}/>
        <SetupState connector={connector}/>
        <SyncedTables connector={connector} selectedCompany={selectedCompany}/>
      </Grid>
  )
}

const SysAdminFivetranConnectors = ({ selectedCompany }) => {
  const [companyFivetranConnectorsArgs, setCompanyFivetranConnectorsArgs] = useState(null);
  const [companyFivetranConnectors, setCompanyFivetranConnectors] = useState(null);
  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      if (selectedCompany.name) console.log("selected company", selectedCompany.name);
      setCompanyFivetranConnectorsArgs({ url: Constants.SERVER_GET_SYSADMIN_INTEGRATION_METADATA_URL + selectedCompany.uuid })
      setCompanyFivetranConnectors(null)
    }
  }, [selectedCompany])

  const handleCompanyFivetranConnectors = (res) => {
    console.log("companyFivetranConnectors", res.connectors)
    if (res) {
      setCompanyFivetranConnectors(res.connectors)
    }
  }

  return (
    <>
      <DirectRequest
        requestArgs={companyFivetranConnectorsArgs}
        afterProcess={handleCompanyFivetranConnectors}
      />
      <Grid item xs={12} container spacing={2}>
        {companyFivetranConnectors && (
          <Grid item xs={12}>
            <Typography variant={"h6"}>Connectors</Typography>
          </Grid>
        )}
        {companyFivetranConnectors && companyFivetranConnectors.map((connector, index) => (
          <FivetranConnector connector={connector} selectedCompany={selectedCompany} key={index}/>
        ))}
      </Grid>
    </>
  )
}

const PreprocessJobRun = ({ run }) => {
  const openRun = () => {
    window.open(run.runUrl, "_blank");
  }
  return (
    <Grid item xs={4} container spacing={2}>
      <Grid item xs={12}>
        <Typography><strong>{run.jobName}</strong></Typography>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item>
          <Typography>startTime:</Typography>
        </Grid>
        <Grid item>
          <Typography>{new Date(run.startTime).toLocaleString()}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems={"center"}>
        <Grid item>
          <Typography>runStatus:</Typography>
        </Grid>
        <Grid item>
          <RunStatus runStatus={run.runStatus}/>
        </Grid>
        <Grid item>
          <IconButton
            onClick={openRun}
          >
            <LaunchIcon sx={{ fontSize: "15px", marginBottom: "1px" }}/>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

const SysAdminPreprocessJobRuns = ({ selectedCompany }) => {
  const [preprocessJobRuns, setPreprocessJobRuns] = useState(null)
  const [preprocessJobRunsArgs, setPreprocessJobRunsArgs] = useState(null)
  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      setPreprocessJobRunsArgs({ url: Constants.SERVER_SYSADMIN_POST_GET_PREPROCESS_JOB_RUNS_URL + selectedCompany.uuid, method: "POST", body: JSON.stringify({}) });
      setPreprocessJobRuns(null);
    }
  }, [selectedCompany])

  const handlePreprocessJobRuns = (res) => {
    console.log("handlePreprocessJobRuns -> res", res)
    if (res && res.databricksJobRunInfoList) {
      setPreprocessJobRuns(res.databricksJobRunInfoList)
    }
  }

  return (
    <>
      <DirectRequest
        requestArgs={preprocessJobRunsArgs}
        afterProcess={handlePreprocessJobRuns}
      />
      <Grid item xs={12} container spacing={2}>
        {preprocessJobRuns && (
          <Grid item xs={12}>
            <Typography variant={"h6"}>Preprocess job runs</Typography>
          </Grid>
        )}
        {preprocessJobRuns && preprocessJobRuns.map((run, index) => (
          <PreprocessJobRun run={run} key={index}/>
        ))}
      </Grid>
    </>
  )
}

export const SysAdminSummary = ({selectedCompany}) => {
  return (
    <Grid container spacing={4}>
      <SysAdminSummaryMetrics selectedCompany={selectedCompany}/>
      <SysAdminFivetranConnectors selectedCompany={selectedCompany}/>
      <SysAdminPreprocessJobRuns selectedCompany={selectedCompany}/>
    </Grid>
  )
}

export default SysAdminSummary;