import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import * as Constants from "../../Constants";
import {setReadyForCustomer, setSysAdminPrimaryFeed, setSelectedTab as setSelectedSysAdminTab} from "../../store/alertsTabsSysAdminSlice";
import {setSelectedTab, setPrimaryFeed} from "../../store/alertsTabsSlice";
import {generateAlertViewArgs, viewAlertTypes, RedirectLoader, generateOrdersDashboardViewArgs} from "./redirectUtils";
import {setAlertNotificationArgs} from "../../store/alertsNotificationSlice";
import {setCompany} from "../../store/alertsSysAdminSlice";
import DirectRequest from "../../API/requests/DirectRequest";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

export const AppRedirect = ({ dashboardID }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const [tempState, setTempState] = useState(null);// holding state to retrieve companies if companyUuid is not falsey
  const [companyArgs, setCompanyArgs] = useState(null)


  useEffect(() => {
    if (localStorage.getItem("dashboardState")) {
      console.log("dashboardState found in localStorage")
      const dashboardState = JSON.parse(localStorage.getItem("dashboardState"));
      setTempState(dashboardState);
      localStorage.removeItem("dashboardState");
    } else {
      console.log("dashboardState not found in localStorage")
      navigate("/")
    }
  }, [])

  useEffect(() => {
    if (tempState) {
      console.log("tempState found", tempState)
      if (!tempState.thisAlert) {
        // Orders dashboard - no alerts - companyUuid in passed state will trigger correct request in RecipeDynamic for sysadmin
        if (!isInternal && !tempState.companyUuid) {
          const ordersDashboardViewArgs = generateOrdersDashboardViewArgs(user, tempState.thisDevApp);
          dispatch(setAlertNotificationArgs(ordersDashboardViewArgs));
        }
        navigate(`/dashboard/${dashboardID}`, { state: tempState });
      } else if (tempState.companyUuid) {
        // sysadmin-initiated openDashboard -> get companies
        console.log("sysAdmin initiated - getting companies")
        setCompanyArgs({ url: Constants.SERVER_SYSADMIN_GET_COMPANIES_URL });
        // also - dispatch setSelectedTab and readyForCustomer to ensure alerts appearing on dashboard match tabs from sysAdminAlertsControls
        dispatch(setSelectedSysAdminTab(tempState.currentTab))
        dispatch(setReadyForCustomer(tempState.readyForCustomer))
        if (!tempState.thisAlert?.inPrimaryFeed) dispatch(setSysAdminPrimaryFeed(false));
      } else {
        // user-initiated openDashboard
        console.log("user initiated - navigating to dashboard")
        dispatch(setSelectedTab(tempState.currentTab))
        if (!tempState.thisAlert?.inPrimaryFeed) dispatch(setPrimaryFeed(false));
        navigate(`/dashboard/${dashboardID}`, { state: tempState });
        if (!isInternal) {
          const alertViewArgs = generateAlertViewArgs(tempState.thisAlert, user, viewAlertTypes.APP);
          dispatch(setAlertNotificationArgs(alertViewArgs));
        }
      }
    }
  }, [tempState, isInternal, user])

  const handleCompanies = (res, tempState) => {
    console.log("handleCompanies companies", res, "tempState", tempState)
    const selectedCompany = res.find(company => company.uuid === tempState.companyUuid);
    dispatch(setCompany(selectedCompany));
    navigate(`/dashboard/${dashboardID}`, { state: tempState });
  }

  const cancelAction = () => {
    window.close();
  }

  return (
    <>
      <DirectRequest
        requestArgs={companyArgs}
        afterProcess={(res) => handleCompanies(res, tempState)}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("Redirect get companies error", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("Redirect get companies catch error", err)}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Grid item>
              <Button
                onClick={cancelAction}
                size="small"
                variant="contained"
                startIcon={<CloseIcon />}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RedirectLoader/>
        </Grid>
      </Grid>
    </>
  )
}

export default AppRedirect;