import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {dashboardConfigsSelectors, updateConfig} from "../../../../../store/dashboardConfigsSlice";
import {
  generateDisplayConfigUpdateArgs,
  generateDisplayConfigUpdateNotification,
} from "./ConfigActionUtils";
import {setAlertNotificationArgs} from "../../../../../store/alertsNotificationSlice";
import React from "react";
import ConfigActionsButtonContent from "./ConfigActionsButtonContent";

export const ConfigActionsButton = ({configAction, row, displayConfigUuid, statusTrackerConfig, disableActions}) => {
  const dispatch = useDispatch();
  const page_params = useParams();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const companyUuid = useSelector(state => state.dashboard.companyUuid);

  const primaryKeyFieldList = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.primaryKeyFieldList);
  const linkedTableForeignFieldMap = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.linkedTableForeignFieldMap);
  const displayConfigTableRows = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.displayConfigTableRows);
  const displayConfigTableRow = displayConfigTableRows?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap).every(([field, value]) => row[field] === value));

  const handleConfigUpdate = (newConfigValue) => {
    const configUpdateArgs = generateDisplayConfigUpdateArgs(companyUuid, newConfigValue, configAction, row, displayConfigUuid, primaryKeyFieldList, linkedTableForeignFieldMap);
    // console.log("configUpdateArgs", configUpdateArgs);
    if (!isInternal && !companyUuid) {// companyUuid means sysAdmin viewing dashboard
      const configUpdateNotification = generateDisplayConfigUpdateNotification(newConfigValue, configAction, row, user, displayConfigUuid, page_params.dashboardID);
      console.log("configUpdateNotification", configUpdateNotification);
      dispatch(setAlertNotificationArgs(configUpdateNotification));
    }
    dispatch(updateConfig(displayConfigUuid, configUpdateArgs));
  }

  return (
    <ConfigActionsButtonContent
      handleConfigUpdate={handleConfigUpdate}
      configAction={configAction}
      displayConfigTableRow={displayConfigTableRow}
      statusTrackerConfig={statusTrackerConfig}
      disableActions={disableActions}
    />
  )
}

export default ConfigActionsButton