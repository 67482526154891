import * as Constants from "../../../../../Constants";
import iso8601Timestamp from "../../../../../helpers/iso8601Timestamp";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import CloseIcon from "@mui/icons-material/Close";
import UndoIcon from "@mui/icons-material/Undo";
import NextPlanIcon from '@mui/icons-material/NextPlan';
import React from "react";
import Typography from "@mui/material/Typography";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { config_options } from "../../../../../store/dashboardConfigsSlice";

export const getPrimaryKeyValueMap = (row, primaryKeyFieldList) => {
  const tempPrimaryKeyValueMap = {};
  primaryKeyFieldList.forEach((primaryKey) => {
    tempPrimaryKeyValueMap[primaryKey] = row[primaryKey];
  })
  // console.log("tempPrimaryKeyValueMap", tempPrimaryKeyValueMap)
  return tempPrimaryKeyValueMap;
}

export const getLinkedTableForeignValueMaps = (row, primaryKeyFieldList, linkedTableForeignFieldMap) => {
  //Map<String, List<String>> linkedTableForeignFieldMap
  const orderedPrimaryValues = primaryKeyFieldList.map((primaryKey) => row[primaryKey]);
  const tempLinkedTableForeignValueMap = {};
  Object.entries(linkedTableForeignFieldMap).forEach(([tableName, foreignFieldMap]) => {
    const tempForeignValueMap = {};
    foreignFieldMap.forEach((field, index) => {
      tempForeignValueMap[field] = orderedPrimaryValues[index];
    })
    tempLinkedTableForeignValueMap[tableName] = tempForeignValueMap;
  })
  // console.log("tempLinkedTableForeignValueMap", tempLinkedTableForeignValueMap);
  return tempLinkedTableForeignValueMap;
}

export const generateDisplayConfigUpdateArgs = (companyUuid, newConfigValue, configAction, row, displayConfigUuid, primaryKeyFieldList, linkedTableForeignFieldMap) => {
  const tempPrimaryKeyValueMap = getPrimaryKeyValueMap(row, primaryKeyFieldList);
  const tempBody = {
    displayConfigTableUuid: displayConfigUuid,
    primaryKeyValueMap: tempPrimaryKeyValueMap,
    displayConfigOptionName: configAction,
    displayConfigOptionValue: newConfigValue,// NO CONFIG ROW YET - means false, FLIP TO TRUE
  }
  if (linkedTableForeignFieldMap) {
    tempBody["linkedTableForeignValueMaps"] = getLinkedTableForeignValueMaps(row, primaryKeyFieldList, linkedTableForeignFieldMap);
  }
  const tempUpdateArgs = {
    url: companyUuid ? Constants.SERVER_SYSADMIN_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL + companyUuid : Constants.SERVER_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL,// TODO - add
    method: "POST",
    body: JSON.stringify(tempBody)
  }
  return tempUpdateArgs;
}

export const iconButtonStyle = {
  padding: "4px",
}

export const customIconButtonStyle = {
  borderRadius: "4px !important",
  padding: "4px",
}

export const getIconButtonStyle = (configAction) => {
  if (configAction === config_options.create_entry) {
    return customIconButtonStyle;
  } else {
    return iconButtonStyle;
  }
}

const CreateEntryIcon = ({ disableActions }) => {
  return (
    <Typography
      sx={{
        color: disableActions ? "rgba(0, 0, 0, 0.26)" : "rgba(0, 0, 0, 0.54)",
        fontWeight: 600,
        border: `2px solid ${disableActions ? "rgba(0, 0, 0, 0.26)" : "rgba(0, 0, 0, 0.54)"}`,
        borderRadius: "4px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "28px",
        width: "34px"
      }}
      component={"div"}
    >
      <span style={{fontFamily: "Barlow Semi Condensed, Arial, sans-serif", fontSize: '10px', lineHeight: "10px"}}>Create</span>
      <span style={{fontFamily: "Barlow Semi Condensed, Arial, sans-serif", fontSize: '10px', lineHeight: "10px"}}>entry</span>
    </Typography>
  )
}

export const getTooltipTitle = (newConfigValue, configAction) => {
  if (configAction === config_options.suppress_alert) {
    return newConfigValue ? "Suppress alert" : "Show alert"
  } else if (configAction === config_options.hide_row) {
    return newConfigValue ? "Hide row" : "Show row"
  } else if (configAction === config_options.status_tracker) {
    return `Change status to ${newConfigValue}`
  } else if (configAction === config_options.create_entry) {
    return "Create journal entry - coming soon"
  } else if (configAction === config_options.send_email) {
    return "Email vendor - coming soon"
  }
}

export const getIcon = (newConfigValue, configAction, disableActions) => {
  if (configAction === config_options.suppress_alert) {
    return newConfigValue ? <NotificationsOffIcon/> : <NotificationAddIcon/>
  } else if (configAction === config_options.hide_row) {
    return newConfigValue ? <CloseIcon/> : <UndoIcon/>
  } else if (configAction === config_options.status_tracker) {
    return <NextPlanIcon/>
  } else if (configAction === config_options.create_entry) {
    return <CreateEntryIcon disableActions={disableActions}/>
  } else if (configAction === config_options.send_email) {
    return <ForwardToInboxIcon/>
  }
}

export const generateDisplayConfigUpdateNotification = (newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid) => {
  const rowInBody = Object.entries(row).map(([key, value]) => `${key}: ${value}<br/>`).join("");
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> set ${configAction} to ${newConfigValue}<br/><br/>display config uuid: ${displayConfigUuid}<br/><br/>app uuid: ${devAppUuid}<br/><br/>row:<br/><br/>${rowInBody}<br/>on ${window.location.origin}<br/><br/>`,
      "subject": `${user} set ${configAction} to ${newConfigValue} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}