export const dummy_apps = [
    {
      "uuid": "dapp_C2AYQ5EXS0MAD4J2CXHDYH447",
      "developerCompanyUuid": "comp_DEMO",
      "name": "fivetran test",
      "fullName": "fivetran test",
      "description": "fivetran test",
      "descriptionLong": "",
      "isPremium": false,
      "subscribersCurrent": 231,
      "price": 185.12,
      "monthlyRevenue": 42762.72,
      "lifetimeRevenue": 0.0,
      "thumbnail": null,
      "requiredSourceTables": [
        {
          "uuid": "stab_8CRZDEFOYD9XXE4Q28CPUAEKT",
          "name": "account",
          "sourceColumns": [],
          "createdOn": "SYSTEM",
          "modifiedOn": null
        }
      ],
      "stripeProductUuid": "prod_OG0KrUDxHA8AQZ",
      "tags": [
        "fdAlert",
        "category__Debug",
        "impact__Debug",
        "categories__Revenue & top-line (profit-growth by inflows)__Invoice: SO disconnect",
        "categories__Expenses & payments (profit-growth by outflows)__Bill: material data change::Bill: double entry::Bill: PO disconnect",
        "categories__Efficiency (compliance & close)__Test Fivetran App Appbuilder",// purposely excluded to non-displayed category for tests
        // special config tags below
        // "view_config__hide_alerts",// hides alerts in dashboard view
        // "link_config__Full_revenue_flow",// generates a link in Header for dashboard view (no alerts are generated by this app for users to click)
        // "link_config_sysadmin__Full_revenue_flow"
      ],
      "createdOn": "junaid@abbasi.com",
      "modifiedOn": "Tue Apr 02 18:05:00 GMT 2024"
    }
  ]
